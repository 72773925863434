<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <app-logo />

          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Bienvenido!
        </b-card-title>
        <b-card-text class="mb-2">
          Inicia sesión en tu cuenta para comenzar
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Correo electrónico"
            >
              <validation-provider
                #default="{ errors }"
                name="correo electrónico"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  :disabled="loginLoader"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@gmail.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="contraseña"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="user.password"
                    :disabled="loginLoader"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Contraseña"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || loginLoader"
            >
              Iniciar sesión
              <feather-icon
                v-if="loginLoader"
                icon="LoaderIcon"
                class="spinner ml-25"
              />
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Eres nuevo aquí? </span>
          <b-link :to="{name:'auth-register'}">
            <span>Crea una cuenta</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import AppLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    AppLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    loginLoader() {
      return this.$store.state.usersStoreModule.loaders.login
    },
  },
  methods: {
    login() {
      this.$store.dispatch('usersStoreModule/login', this.user)
        .then(data => {
          const userData = {
            ability: [{ action: 'manage', subject: 'all' }],
            avatar: '',
            email: data.email,
            fullName: data.name,
            id: data.id,
            role: 'admin',
            username: data.name,
          }
          localStorage.setItem('userData', JSON.stringify(userData))
          useJwt.setToken(data.token)
          this.$ability.update(userData.ability)
          this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Bienvenido ${userData.fullName || userData.username}`,
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'Es bueno tenerte de vuelta',
                },
              })
            })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ocurrió un error',
              text: 'Verifica tus credenciales',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
